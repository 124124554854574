<template>
  <el-dialog  title="标签库"
              :close-on-click-modal="false"
              :visible.sync="isOpenModal"
              append-to-body
              width="500px"
              @closed="closeModal" @open="getList">
    <div style="width: 100%">
      <ch-table :render-option="tableOption" :data="tableList" row-key="id" ref="table"
                :loading="isTableLoading" @select="tableSelect" @select-all="tableSelectAll"
                :props="{width: '100%', height: '300px', size: 'mini'}">
      </ch-table>
    </div>
    <div slot="footer" class="flex_con">
      <ch-button type="ok" @click="handleModal">确定</ch-button>
    </div>
  </el-dialog>
</template>
<script>

export default {
  data(){
    return {
      isOpenModal:false,
      isTableLoading:false,
      tableList:[],
      selectList:[],
      isEdit:false,
    }
  },
  computed:{
    tableOption:function (){
      return [
        { column: "type", prop: "", type: "selection", width: "25px" },
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "名称", prop: "name", showOverflowTooltip: true,},
      ];
    },
  },
  methods:{
    openTagsList(list){
      this.isOpenModal = true
      this.isEdit = false
      this.selectList = list;
      this.getList()
    },
    getList(){
      this.isTableLoading = true;
      this.$curl.get("/hm/hmServiceStaffTagLibrary/getTagLibrary").then((res) => {
        this.tableList = res.data
      }).finally(() => {
        this.isTableLoading = false
        this.handleCheckData()
      });
    },
    tableSelect(val){
      this.selectList = val;
    },
    tableSelectAll(val){
      this.selectList = val;
    },
    handleModal(){
      this.$emit("selectTagList", this.selectList)
      this.closeModal()
    },
    handleCheckData() {
      this.$refs.table.getTableRef() &&
      this.$refs.table.getTableRef().clearSelection();
      this.idList = this.selectList?.map((e) => e.id)
      this.tableList.forEach((row) => {
        if (this.idList.includes(row.id)) {
          this.$refs.table.getTableRef().toggleRowSelection(row, true)
        }
      })
    },
    closeModal(){
      this.isOpenModal = false
    }
  }
}
</script>
<style scoped lang="scss">

</style>